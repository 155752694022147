import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairReplacementMen = () => {

  const seoData = {
    title: 'Hair Replacement For Men in Delhi - Radiance Hair Studio',
    description: 'Experience top-quality hair replacement for men in Delhi at Radiance Hair Studio. Regain your confidence with natural-looking results. Book an appointment now. ',
    keywords: ['Hair Replacement For Men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Replacement-System-for-Men's.jpg",
      "https://www.radiancehairstudio.com/hair-replacement-for-men2.png",
      "https://www.radiancehairstudio.com/hair-replacement-for-men3.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "serviceType": "Hair Replacement System for Men's",
    "disambiguatingDescription": "Hair Replacement For Men in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-replacement-for-men.php",
    "category": "https://www.radiancehairstudio.com/hair-replacement-for-men.php",
    "description": "Experience top-quality hair replacement for men in Delhi at Radiance Hair Studio. Regain your confidence with natural-looking results. Book an appointment now."
  };    

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Replacement-System-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Replacement for Men's</h2>
          <p>
            The best way to deal with your baldness, semi-permanent hair loss and even accept a frequent change of hairstyles is to go for 
            <a href="/gallery.php" style={{ color: "#373737"}}> hair wigs</a> in Delhi. You will have a number of choices at your hand in the case. You won't have any adverse consequences beyond the aforementioned.
          </p>
  
          <h2>Radiance’s Masterstroke</h2>
          <p>
          Radiance Hair Salon is the perfect name that stands at this level, dedicated supporting on its experienced professionals, to serve you in the best possible manner. In addition to showing you our catalogue and offering you options, we enjoy advising our customers on the best style to purchase given their budget. 
          </p>
          <p>
          Each man has his own preferences, and his facial hairstyle suggests a specific style of Delhi hair replacement. Therefore, it is crucial to give you the best advice possible in this regard, guaranteeing that your investment is worthwhile. 
          </p>
          <p>
          At Radiance, We guarantee the same for any externally applied wig, extension, and replacement patterns. Our techniques are totally painless, and you can see results right away using any of them. You can achieve a very natural look and feel by replacing it with 100% genuine human hair patches and entirely safe glues, clips, and tapes.Additionally, our qualified experts are available at all times to help you through the entire procedure so that you can continue to be truly pleased with the new appearance. 
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-replacement-for-men2.png",
      largeImg: "/hair-replacement-for-men3.png",
      content: (
        <div>
          <h2>Advantages of Hair Replacement Systems</h2>
  
          <ul>
            <li>
            Enhancing your style shouldn’t be a challenge, especially if you feel hesitant about experimenting with your natural hair. With high-quality hair wigs for men and hair extensions, achieving a modern and stylish look is effortless. Whether you're dealing with baldness or partial hair loss, these solutions offer an instant transformation, giving you a confident and refreshed appearance in just minutes.
            </li>
            <li>
              <h6>Safe and Non-Damaging Solution</h6>
              Hair replacement systems, including wigs and extensions, are applied externally, ensuring zero direct impact on your scalp. The adhesives and tapes used in the application process are dermatologically tested and of superior international quality, minimizing any risk of irritation or side effects.
            </li>
            <li>
            <h6>Protects Natural Hair</h6>
            One of the biggest advantages of using hair wigs for men or <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>
            extensions</a> is that they do not interfere with your natural hair. Since the application process does not involve direct chemical treatments, your hair remains undamaged and intact. The adhesives are applied to the scalp, keeping your natural roots completely unaffected.
            </li>
            <li>
             <h6>Easy Maintenance and Versatile Use</h6>
             Hair replacement systems are designed for convenience. You can wash, condition, and style them just like natural hair. If you opt for clip-on extensions, they can be removed and reapplied whenever needed, making them an easy, hassle-free solution for those who want flexibility in their styling routine.
            </li>
            <li>
  
              You can apply shampoos, conditioners and even serums on the
              extensions. In the case of clip-on, you can even open those at times
              and wear them, when you need. So, it’s completely hassle-free, in
              terms of use too.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of Hair Replacement Systems",
      description:
        "We know that it is not very easy to go for a complete transformation process. Many questions might have come to your mind while thinking to go for a hair replacement. That’s why here we tried to answer some them.",
      description2: "",
      faq: [
        {
          title: "Why shall I go for this replacement?",
          description:
            "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement.Use this hairstyle technique, which involves wig pasting, in every situation.International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi. ",
        },
        {
          title: "Is there any side effect?",
          description:
            "All the processes that are applied by Radiance are external and hence there remains no chance of side-effects. Few types of glue or tapes are used, but those are non-effecting for your skull.",
        },
        {
          title: "Can there be any hair-loss?",
          description:
            "There is absolutely no chance of hair loss for you while applying hair wigs in Delhi. The treatment that is done here at Radiance is done on your skull and out professionals avoids treating your existing hair’s root. So, no chances of hair loss remain.",
        },
        {
          title: "Can I change the style?",
          description:
            "In case of Bonding or any other type of hair wigs, you can easily change the applied hairstyle. When you reach Radiance, our professionals will be guiding you in this regards. You can apply colors on your hair, use different patches.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Replacement System for Men's" banner="/hair-replacement-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Replacement for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairReplacementMen;
